export const lightTheme = {
    theme: "light",
    backgroundColor: "#fdfdfd",
    color: "#070707",
    scrollColor: "#606060",
    loading: "#ff8820",
};

export const darkTheme = {
    theme: "dark",
    backgroundColor: "#0d1117",
    color: "#f0f6fc",
    scrollColor: "#606060",
    loading: "#ff8820",
};

export const hiddenTheme = {
    theme: "hidden",
    backgroundColor: "#181818",
    color: "#fff",
    scrollColor: "red",
    loading: "red",
};

export const theme = {
    lightTheme,
    darkTheme,
    hiddenTheme,
};

export default theme;
