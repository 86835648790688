import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import "components/shake.css";

const globalStyles = createGlobalStyle`
    ${reset};
    
    a{
        text-decoration:none;
        color:inherit;
    }

    *{
        box-sizing: border-box;
    }

    body{
        max-width: 100vw;
        min-height: 100vh;

        /* position: relative; */

        font-family: 'Nanum Gothic', sans-serif;
        font-weight: bold;
        font-size: 15px;
        font-weight: 400;

        background-color: ${({ theme }: { theme: themeIState }) =>
            theme.backgroundColor};
        color: ${({ theme }: { theme: themeIState }) => theme.color};
        transition: all 0.2s;
        
        -webkit-user-select:none;
        -moz-user-select:none;
        -ms-user-select:none;
        user-select:none;
        
        -webkit-tap-highlight-color: transparent;
        
        overflow-x: hidden;

        padding: 0;
        margin: 0;
        
        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: ${({ theme }: { theme: themeIState }) =>
                theme.scrollColor};
            background-clip: padding-box;
            
            border: 2px solid transparent;
            border-top-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
`;

export default globalStyles;
