import { createAction, handleActions } from "redux-actions";

const SET_SCREEN_TYPE = "responsive/SET_SCREEN_TYPE";

export const getScreenType = createAction(
    SET_SCREEN_TYPE,
    (screenType: screenTypeIState) => {
        return screenType;
    }
);

const initialState: responsiveIState = {
    screenType: {
        isPC: false,
        isTablet: false,
        isMobile: false,
    },
};

const responsive = handleActions<responsiveIState, screenTypeIState>(
    {
        [SET_SCREEN_TYPE]: (state, { payload }) => ({
            ...state,
            screenType: payload,
        }),
    },
    initialState
);

export default responsive;
