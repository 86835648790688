import React from "react";
import styled, { css, keyframes } from "styled-components";

interface propsIState {
    pageIn: boolean;
}

function PageTransition(props: propsIState) {
    const { pageIn } = props;
    return <Container pageIn={pageIn}></Container>;
}

export default PageTransition;

const appear = keyframes`
    0% {
        transform: translate3d(0, 100%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
`;
const disappear = keyframes`
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(0, -100%, 0);
    }
`;

const Container = styled.div<{ pageIn: boolean }>`
    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;
    left: 0;

    background-color: #181818;

    ${(props) =>
        props.pageIn
            ? css`
                  transform: translate3d(0, 100%, 0);
                  animation: ${appear} 0.5s ease-out forwards;
              `
            : css`
                  transform: translate3d(0, 0, 0);
                  animation: ${disappear} 0.5s ease-out forwards;
              `}

    z-index: 999;
`;
