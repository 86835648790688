import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ThemeProvider } from "styled-components";

import Router from "components/router";
import GlobalStyles from "components/globalStyles";
import { lightTheme, darkTheme, hiddenTheme } from "components/theme";
import { getScreenType } from "modules/responsive";

function App() {
    const initTheme = window.location.pathname.startsWith("/swy9hu3z78ta5fxt")
        ? "hidden"
        : "light";
    const [theme, setTheme] = useState<"light" | "dark" | "hidden">(initTheme);

    const isLight = theme === "light";
    const isDark = theme === "dark";
    const toggleTheme = () => {
        if (isLight) {
            setTheme("dark");
        } else {
            setTheme("light");
        }
    };

    const dispatch = useDispatch();

    const isPC = useMediaQuery({ query: "(min-width: 1024px)" });
    const isTablet = useMediaQuery({
        query: "(min-width: 650px) and (max-width: 1023px)",
    });
    const isMobile = useMediaQuery({
        query: "(max-width: 649px)",
    });

    useEffect(() => {
        dispatch(
            getScreenType({
                isPC,
                isTablet,
                isMobile,
            })
        );
    });

    const routerPassProps = {
        theme,
        toggleTheme,
        setTheme,
    };

    return (
        <ThemeProvider
            theme={isLight ? lightTheme : isDark ? darkTheme : hiddenTheme}
        >
            <BrowserRouter>
                <GlobalStyles />
                <Router {...routerPassProps} />
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
