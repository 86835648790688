import React, { useState, useEffect, Suspense, lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { useHistory, useLocation } from "react-router";

import { useSelector } from "react-redux";
import styled from "styled-components";

import Loading from "components/loading";

import PageTransition from "components/transition";

const TopHeader = lazy(() => import("components/header/top"));
const BottomHeaderPC = lazy(() => import("components/header/bottom/desktop"));
const BottomHeaderMobile = lazy(
    () => import("components/header/bottom/mobile")
);
const MainFooter = lazy(() => import("components/footer/main"));
const HiddenHeader = lazy(() => import("components/header/hidden"));
const HiddenFooter = lazy(() => import("components/footer/hidden"));
const HiddenMessage = lazy(() => import("components/hidden/message"));

const Home = lazy(() => import("pages/basic/main"));
const Intro = lazy(() => import("pages/basic/intro"));
const Support = lazy(() => import("pages/basic/support"));
const Campaign = lazy(() => import("pages/basic/campaign"));
const DetailCampaign = lazy(() => import("pages/basic/campaign/detail"));
const Sponsor = lazy(() => import("pages/basic/sponsor"));
const HiddenHome = lazy(() => import("pages/hidden/main"));
const HiddenNotice = lazy(() => import("pages/hidden/notice"));
const HiddenDetail = lazy(() => import("pages/hidden/notice/detail"));
const HiddenHowToUse = lazy(() => import("pages/hidden/howToUse"));
const HiddenLogin = lazy(() => import("pages/hidden/auth/loginContainer"));
const HiddenNotfound = lazy(() => import("pages/hidden/404"));

const Router = (props: propsIState) => {
    const location = useLocation();
    const history = useHistory();
    const { theme, setTheme } = props;
    const { isPC } = useSelector(
        ({ responsive }: { responsive: responsiveIState }) =>
            responsive.screenType
    );

    const [isHiddenPage, setHidden] = useState(false);
    const [checkSignIn, setCheckSignIn] = useState(false);
    const [showPlutoIn, setShowPlutoIn] = useState<boolean>(false);
    const [showHadesIn, setShowHadesIn] = useState<boolean>(false);

    useEffect(() => {
        setHidden(false);
        if (location.pathname.startsWith("/swy9hu3z78ta5fxt")) {
            setTheme("hidden");
            setHidden(true);
            if (window.sessionStorage.getItem("code") !== "HELLGATE") {
                setTheme("light");
                window.scrollTo(0, 0);
                history.push("/");
            }

            if (window.sessionStorage.getItem("auth") === undefined) {
                setCheckSignIn(false);
            }
        } else if (theme === "hidden") {
            setTheme("light");
        }
    }, [location, history, setTheme, theme]);

    useEffect(() => {
        const authInfo = JSON.parse(
            window.sessionStorage.getItem("auth") || "{}"
        ) as { id: string; password: string };

        if (authInfo.id === "DESIN" && authInfo.password === "HELL") {
            setCheckSignIn(true);
        }
    }, []);

    useEffect(() => {
        if (showHadesIn)
            setTimeout(() => {
                setShowHadesIn(false);
            }, 300);
    }, [showHadesIn]);

    return (
        <Suspense
            fallback={
                <LoadingContainer>
                    <LoadingAnimationContainer>
                        <Loading />
                    </LoadingAnimationContainer>
                </LoadingContainer>
            }
        >
            <>
                {isHiddenPage ? (
                    <HiddenHeader
                        checkSignIn={checkSignIn}
                        setCheckSignIn={setCheckSignIn}
                    />
                ) : (
                    <>
                        <TopHeader {...props} />
                        {isPC ? <BottomHeaderPC /> : <BottomHeaderMobile />}
                    </>
                )}
                <Switch>
                    <Route
                        path="/"
                        exact
                        render={() => (
                            <Home
                                theme={theme}
                                setShowPlutoIn={setShowPlutoIn}
                            />
                        )}
                    />
                    <Route path="/intro" exact component={Intro} />
                    <Route path="/support" exact component={Support} />
                    <Route path="/campaign" exact component={Campaign} />
                    <Route
                        path="/campaign/:idx"
                        exact
                        component={DetailCampaign}
                    />
                    <Route
                        path="/sponsor"
                        exact
                        render={() => <Sponsor theme={theme} />}
                    />
                    <Route
                        path="/swy9hu3z78ta5fxt"
                        exact
                        render={() => (
                            <HiddenHome
                                setShowPlutoIn={setShowPlutoIn}
                                setShowHadesIn={setShowHadesIn}
                            />
                        )}
                    />
                    <Route
                        path="/swy9hu3z78ta5fxt/notice/:id"
                        exact
                        component={HiddenDetail}
                    />
                    <Route
                        path="/swy9hu3z78ta5fxt/notice"
                        exact
                        component={HiddenNotice}
                    />
                    <Route
                        path="/swy9hu3z78ta5fxt/howToUse"
                        exact
                        component={HiddenHowToUse}
                    />
                    <Route
                        path="/swy9hu3z78ta5fxt/login"
                        exact
                        render={() => <HiddenLogin checkSignIn={checkSignIn} />}
                    />
                    <Route
                        path="/swy9hu3z78ta5fxt/register"
                        exact
                        component={HiddenNotfound}
                    />
                    <Route
                        path="/swy9hu3z78ta5fxt/notfound"
                        exact
                        component={HiddenNotfound}
                    />
                    {window.sessionStorage.getItem("code") === "HELLGATE" &&
                    location.pathname.startsWith("/swy9hu3z78ta5fxt") ? (
                        <Redirect from="*" to="/swy9hu3z78ta5fxt" />
                    ) : (
                        <Redirect from="*" to="/" />
                    )}
                </Switch>
                {isHiddenPage && checkSignIn && <HiddenMessage />}
                {isHiddenPage ? <HiddenFooter /> : <MainFooter theme={theme} />}
                {showPlutoIn && <PageTransition pageIn={true} />}
                {location.pathname === "/swy9hu3z78ta5fxt" && showHadesIn && (
                    <PageTransition pageIn={false} />
                )}
            </>
        </Suspense>
    );
};

export default Router;
interface propsIState {
    theme: "light" | "dark" | "hidden";
    toggleTheme: () => void;
    setTheme: React.Dispatch<React.SetStateAction<"light" | "dark" | "hidden">>;
}

const LoadingContainer = styled.div`
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LoadingAnimationContainer = styled.div`
    width: 20%;
    height: 20%;
`;
